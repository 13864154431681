import Link from "../../components/Link";
import Logo from "../../images/logo.svg";

export default function Header() {
  return (
    <header className="absolute top-2 z-30 w-full md:top-6">
      <div className="px-4 sm:px-6">
        <div className="mx-auto max-w-3xl">
          <div className="flex h-14 items-center justify-between rounded-lg border border-transparent px-3 [background:linear-gradient(theme(colors.white),theme(colors.white))_padding-box,linear-gradient(120deg,theme(colors.zinc.300),theme(colors.zinc.100),theme(colors.zinc.300))_border-box]">
            {/* Site branding */}
            <div className="mr-2 shrink-0">
              {/* Logo */}
              <Link
                className="flex h-8 w-8 items-center justify-center rounded bg-white shadow-sm shadow-zinc-950/20"
                href="/"
              >
                <img src={Logo} alt="Ontune Logo" />
              </Link>
            </div>
            <b>OntuneAI</b>

            {/* Desktop navigation */}
            <nav className="flex grow">
              {/* Desktop sign in links */}
              <ul className="flex grow flex-wrap items-center justify-end">
                {/* <li>
                  <Link className="text-sm font-medium text-zinc-500 hover:text-zinc-900 px-3 lg:px-5 py-2 flex items-center transition" href="/login">Log in</Link>
                </li> */}
                <li className="ml-1">
                  <a
                    className="w-full rounded-md bg-black px-4 py-2 text-sm text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-offset-2"
                    href="https://calendly.com/saisatwik-ontune/30min"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Request Demo
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
